@import url(assets/theme/main.scss);

.header-menu {
  top: 6.04vw !important;
  .ant-dropdown-menu {
    width: 14.58vw;
  }
  .ant-dropdown-menu .ant-dropdown-menu-title-content {
    padding: 0 !important;
  }
  .ant-dropdown-menu {
    .ant-dropdown-menu-item:first-child {
      padding: 1.25vw 1.67vw 0.63vw !important;
    }
    .ant-dropdown-menu-item:last-child {
      padding: 0.63vw 1.67vw 1.25vw !important;
    }
  }
  .ant-dropdown-menu-item {
    padding: 0.63vw 1.67vw !important;
  }
  .ant-dropdown-menu-item-divider {
    margin: 0.21vw 0 !important;
  }
  img {
    margin-right: 1.25vw;
    height: 1.25vw;
  }
  span {
    font-size: 0.83vw;
    line-height: 0.94vw;
  }
  @media only screen and (max-width: 1280px) {
    top: 77.31px !important;
    .ant-dropdown-menu {
      width: 186.62px;
    }
    .ant-dropdown-menu {
      .ant-dropdown-menu-item:first-child {
        padding: 16px 21.38px 8.06px !important;
      }

      .ant-dropdown-menu-item:last-child {
        padding: 8.06px 21.38px 16px !important;
      }
    }
    .ant-dropdown-menu-item {
      padding: 8.06px 21.38px !important;
    }
    .ant-dropdown-menu-item-divider {
      margin: 2.69px 0 !important;
    }
    img {
      margin-right: 16px;
      height: 16px;
    }
    span {
      margin-right: 10.62px;
      line-height: 12.03px;
    }
  }
}

.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  .ant-dropdown-trigger {
    box-shadow: unset !important;
  }
}

.heder-background {
  background: #ffffff;
  box-shadow: 0px 1px 8px #eaeaea;
  animation: fadeIn 0.2s ease-out;
}

.heder-out {
  animation: fadeOut 0.2s ease-out;
}

@keyframes fadeIn {
  0% {
    background: unset;
  }
  100% {
    background: #ffffff;
    box-shadow: 0px 1px 8px #eaeaea;
  }
}

@keyframes fadeOut {
  0% {
    background: #ffffff;
    box-shadow: 0px 1px 8px #eaeaea;
  }
  100% {
    background: unset;
  }
}

.ant-result-404 {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .ant-result-image {
    margin-top: 0;
  }
}

.modal-alert-custom {
  font-family: 'Prompt', sans-serif;
  font-family: 'Prompt', sans-serif;
  font-style: normal;
  .box-logo {
    position: relative;
    height: 120px;
    width: 120px;
    .logo {
      object-fit: cover;
      height: 120px;
      width: 120px;
    }
    .locked {
      position: absolute;
      right: -6px;
    }
  }
  .ant-modal-confirm-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-modal-confirm-body-wrapper {
    height: 100%;
    position: relative;
  }
  .ant-modal-confirm-title {
    color: #1d1d42;
    font-size: 23px !important;
    margin-top: 40px;
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-weight: 400;
    font-size: 18px;
    color: #b1b1b1;
    margin-top: 16px;
  }
  .ant-modal-content {
    border-radius: 1.3rem;
  }
  .ant-modal-body {
    height: 444px;
    padding: 40px 38px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-confirm-btns {
    text-align: center;
    float: initial !important;
    position: absolute;
    width: 100%;
    display: flex;
    bottom: 0;

    .ant-btn-default {
      width: 190px;
      height: 54px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      border: 1px solid #2e3c4f !important;
      border-radius: 16px;
      background-color: #fff;
      color: #fff;
      border: none;

      span {
        color: #2e3c4f;
      }
    }

    .ant-btn-primary {
      margin-left: 24px !important;
      width: 190px;
      height: 54px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      border: 1px solid #2e3c4f;
      border-radius: 16px;
      background-color: #2e3c4f;
      color: #fff;
      border: none;

      span {
        color: #fff;
      }
    }
  }
}

.ant-input-affix-wrapper {
  width: 30%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 1.125rem;
  border: 1px solid var(--light-gray);
  border-radius: 20px;
}

.modal-cancel-create-custom {
  .ant-btn {
    border-radius: 8px !important;

    &:hover {
      border-color: var(--black) !important;
      color: var(--black) !important;
    }
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-weight: 500;
  }

  .ant-modal-content {
    border-radius: 1.3rem;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-confirm-btns {
    text-align: center;
    float: initial !important;
    margin-top: 24px !important;

    .ant-btn-primary {
      min-width: 70px;
      border-radius: 8px;
      background-color: var(--warning);
      border: none;

      span {
        color: #fff;
      }
    }
  }
}

.modal-success-custom {
  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-weight: 500;
  }

  .ant-modal-content {
    border-radius: 1.3rem;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-confirm-btns {
    text-align: center;
    float: initial !important;

    .ant-btn-primary {
      min-width: 70px;
      border-radius: 8px;
      background-color: var(--success);
      color: #fff;
      border: none;

      span {
        color: #fff;
      }
    }
  }
}

.modal-error-custom {
  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-weight: 500;
  }

  .ant-modal-content {
    border-radius: 1.3rem;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-confirm-btns {
    text-align: center;
    float: initial !important;

    .ant-btn-primary {
      min-width: 70px;
      border-radius: 8px;
      background-color: var(--error);
      color: #fff;
      border: none;

      span {
        color: #fff;
      }
    }
  }
}

.modal-cancel-custom {
  .ant-btn {
    border-radius: 8px !important;

    &:hover {
      border-color: var(--black) !important;
      color: var(--black) !important;
    }
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    font-weight: 500;
  }

  .ant-modal-content {
    border-radius: 1.3rem;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-confirm-btns {
    text-align: center;
    float: initial !important;
    margin-top: 24px !important;

    .ant-btn-primary {
      min-width: 70px;
      border-radius: 8px;
      background-color: var(--error);
      border: none;

      span {
        color: #fff;
      }
    }
  }
}

.tcdp-break-word {
  word-break: break-word;
}

.ant-table-content {
  overflow: unset !important;
}

.outer-circle-cluster:before {
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 70px;
  width: 70px;
  background: rgba(0, 192, 138, 0.2);
  animation: scale 2s infinite;
  border-radius: 50%;
}

.outer-circle-cluster {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00c08a;
  border-radius: 50%;
  margin: 100px auto;
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.outer-circle:before {
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  background: rgba(0, 192, 138, 0.2);
  animation: scale 2s infinite;
  border-radius: 50%;
}

.outer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00c08a;
  border-radius: 50%;
  margin: 100px auto;
  width: 25px;
  height: 25px;
}

.inner-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: white;
  z-index: 5;
  position: absolute;
  width: 10px;
  height: 10px;
}

.textMarker {
  margin-top: 60px;
  background: rgba(0, 192, 138, 0.6);
  padding: 3px 10px;
  border-radius: 4px;
  color: white;
}

.outer-circle-offline:before {
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.outer-circle-offline {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9b9b9b;
  border-radius: 50%;
  margin: 100px auto;
  width: 25px;
  height: 25px;
}

.inner-circle-offline {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #c6c6c6;
  z-index: 5;
  position: absolute;
  width: 10px;
  height: 10px;
}

.textMarker-offline {
  margin-top: 60px;
  background: #9b9b9b;
  padding: 3px 10px;
  border-radius: 4px;
  color: white;
}

@keyframes scale {
  0% {
    transform: scale(0.5, 0.5);
  }
  20% {
    border-color: #909090;
  }
  100% {
    border-color: transparent;
    transform: scale(1.5, 1.5);
  }
}

.outer-circle-sos:before {
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 195px;
  height: 195px;
  background: radial-gradient(37.78% 50% at 50% 50%, rgba(226, 87, 53, 0) 0%, rgba(226, 87, 53, 0.4) 100%);
  animation: scaleIn 2s infinite;
  border-radius: 50%;
  border: 3px solid #e25735;
}
.outer-circle-sos {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e25735;
  border-radius: 50%;
  margin: 100px auto;
  width: 30px;
  height: 30px;
}

.textMarker-sos {
  margin-right: 110px;
  background: #e25735;
  padding: 3px 10px;
  border-radius: 4px;
  color: white;
}

@keyframes scaleIn {
  from {
    transform: scale(0.8, 0.8);
    opacity: 1;
  }
  to {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.outer-circle-online-iot:before {
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 195px;
  height: 195px;
  background: radial-gradient(37.78% 50% at 50% 50%, rgba(0, 192, 138, 0) 0%, rgba(0, 192, 138, 0.4) 100%);
  animation: scaleIn 2s infinite;
  border-radius: 50%;
  border: 3px solid #00c08a;
}
.outer-circle-online-iot {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00c08a;
  border-radius: 50%;
  margin: 100px auto;
  width: 30px;
  height: 30px;
}

.textMarker-online-iot {
  margin-right: 110px;
  background: #00c08a;
  padding: 3px 10px;
  border-radius: 4px;
  color: white;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  margin: 0px 6px !important;
  border-radius: 6px !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff8f4 !important;
  margin: 0px 6px !important;
  border-radius: 6px !important;
}

.ant-select-item-option {
  margin: 0px 6px !important;
}
