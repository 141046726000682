
.gm-style .gm-style-iw-tc::after {
  display: none;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
/* .gm-style .gm-style-iw-c {
  padding: 0px;
  border-radius: 8px;
} */

.gm-style iframe + div {
  border: none !important;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}