/* .otp-button {
  margin-top: 15px;
}
.otp-button span {
  border-bottom: 1px solid;
  line-height: 10px;
}

.otp-input input {
  width: 100% !important;
  height: 88px;
  margin: 0 5px;
  background-color: #3aa859;
  border: 1px solid #3aa859;

  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 52px;
  text-align: center;

  color: #ffffff;
  caret-color: #f36b24;
}

.otp-input input::selection {
  color: #f36b24;
  background: #ffffff !important;
}

.otp-input input:focus-visible {
  outline: none !important;
  border: 1px solid #f36b24;
  color: #150f0b;
  background-color: #ffffff !important;
}

otp-input input:not(:empty) {
  background-color: #00c08b !important;
  border-color: #00c08b !important;
  color: #fff !important;
} */

.otp-input {
  width: 100% !important;
  height: 88px;
  margin: 0 4px;
  border: 1px solid #ffc4aa;
  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 48px;
  /* line-height: 52px; */
  text-align: center;
  color: #ffffff;
  caret-color: #f36b24;
}

.otp-input::selection {
  color: #f36b24;
  background: #ffffff !important;
}

.otp-input:focus-visible {
  outline: none !important;
  border: 1px solid #f36b24;
  color: #150f0b;
  background-color: #ffffff !important;
}

.otp-input.otp-filled {
  background-color: #3aa859 !important;
  border-color: #3aa859 !important;
  color: #ffffff !important;
}

.otp-input.error {
  background-color: #ffe4e4 !important;
  border-color: #f85858 !important;
  color: #f85858 !important;
}

@media only screen and (max-width: 768px) {
  .otp-input {
    height: 56px;
    font-size: 31px;
  }
}

@media only screen and (max-width: 375px) {
  .otp-input {
    height: 56px;
    font-size: 31px;
  }
}
