.sider {
  height: 100%;
  
}

.text-menu {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  padding: 0 10px 0 10px;
  display: flex;
  align-items: center;
  height: 44px;
}

.sub-sider-menu {
  margin: 0;
  background-color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.icon {
  height: 24px;
  font-size: 28px;
}

.icon img {
  height: 24px;
}
