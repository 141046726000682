#option-button {
  padding: 10px;
  position: absolute;
  bottom: 8px;
  right: 0px;
  z-index: 999;
}

.ant-btn-primary {
  background-color: #f36b24;
  border: #f36b24;
  color: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Prompt';
  width: 120px;
  height: 48px;
}
